import { Button, useDataProvider, useRecordContext, useNotify } from "react-admin";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

export const DownloadButton = () => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const notify = useNotify();

    const mutation = useMutation({mutationFn: () => dataProvider.downloadSema(record.id)});

    return (
        <Button 
            label="Download Products" 
            onClick={(e) => { 
                e.stopPropagation(); 
                mutation.mutate();
                notify('Products downloading');
            }} 
            disabled={mutation.isPending}
        />
    );
}