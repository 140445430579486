import {
    useDataProvider,
} from 'react-admin';
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';

export const PDMImportButton = (props: any) => {
    const dataProvider = useDataProvider();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            dataProvider.productImportPDM(file)
                .then(() => {
                    console.log('PDM file imported');
                })
                .catch((error: any) => {
                    console.error('Error importing PDM file', error);
                });
        }
    }

    return (
        <>
            <input
                accept=".csv"
                style={{ display: 'none' }}
                id="text-button-file"
                type="file"
                onChange={handleChange}
            />
            <label htmlFor="text-button-file" style={{ display: 'inline-flex', alignItems: 'center'}}>
                <Button
                    color="primary"
                    component="span"
                >
                    <GetAppIcon style={{ transform: 'rotate(180deg)' }}/>
                    <span style={{ marginLeft: '0.5em' }}>Import PDM</span>
                </Button>
            </label>
        </>
    )
}